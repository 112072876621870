.link {
  display: flex;
  align-items: center;
  padding: 8px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border-radius: 4px;

  &:active {
    position: relative;
    top: 1px;
  }

  &:hover {
    background: var(--primary-8, rgba(57, 78, 202, 0.08));

    .actions {
      display: flex;
    }
  }

  .avatar {
    width: 80px;
    height: 50px;
    flex-grow: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 12px;
    border-radius: 4px;
  }

  .details {
    flex-grow: 2;

    .title {
      color: var(--Text-Text-Main, var(--text-primary, #011e3d));

      /* desktop/subtitle2 */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      margin-bottom: 4px;
    }

    .url {
      color: var(--text-caption, #738499);

      /* desktop/body2 */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }

  .actions {
    flex-grow: 0;
    display: none;

    .edit {
      svg {
        color: #738499;
      }
    }

    .delete {
      svg {
        color: #fe3c3c;
      }
    }
  }
}
