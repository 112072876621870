.club {
  .buttons {
    transform: scale(0.9);
  }

  .toolbar {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 16px;

    .tabs {
      flex-grow: 2;
    }

    .view {
      flex-grow: 0;
    }
  }

  .management {
    display: flex;

    .title {
      margin-right: 5px;
    }

    .count {
      display: block;
      width: 20px;
      height: 20px;
      background: var(--other-error, #ff316f);
      border-radius: 50%;

      span {
        color: var(--white, #fff);
        font-size: 10px;
        font-family: Roboto;
        font-weight: 500;
        position: relative;
        bottom: 1px;
      }
    }
  }

  .bar {
    display: flex;
    align-items: center;

    .search {
      flex-grow: 2;
    }

    .add {
      flex-grow: 0;
      margin-left: 12px;
    }

    .managementButton {
      flex-grow: 0;
      margin-left: 12px;
    }
  }
}
