.viewer {
  &[data-active='active'] {
    cursor: pointer;
  }
}

.backing {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 200px;
  text-align: center;
}
