.invite {
  .description {
    color: var(--text-primary, #21253e);

    /* desktop/body2 */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-bottom: 16px;
  }

  .link {
    border-radius: var(--radius-S, 8px);
    background: rgba(36, 153, 239, 0.16);
    padding: 12px;
    display: flex;
    // cursor: pointer;

    .url {
      overflow: hidden;
      color: #2499ef;
      text-overflow: ellipsis;

      /* desktop/body2 */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      background: #fff;
      border-radius: var(--radius-S, 8px);
      border: 1px solid var(--Grey-300, #e9eff6);
      background: var(--White, #fff);
      padding: 15px 12px 13px 12px;
      line-height: 100%;
      margin-right: 12px;
      flex-grow: 2;
    }

    .button {
      button {
        border-radius: var(--radius-XS, 4px);
        background: var(--Primary-500, #2499ef);
        color: var(--White, #fff);
        text-align: center;

        /* desktop/subtitle2 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        padding: 12px 20px;
        border: none;
        flex-grow: 0;
        cursor: pointer;

        &:active {
          position: relative;
          top: 1px;
        }
      }
    }
  }
}
