.modal {
  .description {
    color: var(--Text-Text-Muted-300, var(--text-caption, #738499));

    /* desktop/caption */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-bottom: 16px;
  }

  .section {
    margin-bottom: 16px;

    .label {
      color: var(--Text-Text-400, var(--text-secondary, #335474));
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 6px;
    }

    .content {
    }
  }

  .footer {
    margin-top: 24px;
    text-align: right;
  }
}
