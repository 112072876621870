.residency {
  .exclude {
    display: block;
  }

  .status {
    display: flex;
    align-items: center;

    .inviteAgain {
      margin-left: 20px;
    }

    button {
      white-space: nowrap;
    }
  }
}

.order {
  color: var(--Text-Text-400, var(--text-secondary, #335474));
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;

  display: flex;

  .label {
    flex-grow: 2;
  }

  .date {
    flex-grow: 0;
  }
}

.friendLabel {
  display: flex;
  align-items: center;

  .frientIcon {
    color: #2499ef;
    font-size: 16px;
    margin-left: 2px;
  }
}

.excludePlaceholder {
  margin-top: 30px;
  color: #738499;

  /* desktop/caption */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  a {
    color: #2499ef;
  }
}
