.member {
  display: flex;
  margin-bottom: 20px;
  display: flex;
  padding: 8px;
  border-radius: 8px;

  &:hover {
    background: var(--primary-8, rgba(57, 78, 202, 0.08));

    .actions {
      display: block;
    }
  }

  .avatar {
    flex-grow: 0;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  .details {
    flex-grow: 2;
    margin-right: 15px;

    .header {
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      .name {
        overflow: hidden;
        color: var(--text-primary, #21253e);
        text-overflow: ellipsis;

        /* desktop/subtitle1 */
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 146.667% */
        margin-right: 4px;
      }

      .icon {
        position: relative;
        top: 2px;
      }
    }

    .description {
      color: var(--text-secondary, #77798a);

      /* desktop/caption */
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }

    .telegram {
      border-radius: 1000px;
      background: var(--white-main, #fff);
      color: var(--text-primary, #21253e);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 18.2px */
      padding: 6px 12px 6px 6px;
      display: inline-flex;
      align-items: center;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

      svg {
        display: block;
        font-size: 13px;
        color: #21253e;
        margin-right: 3px;
      }
    }
  }

  .actions {
    display: none;
  }
}
