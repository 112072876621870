.start {
  width: 700px;
  margin-left: auto;
  margin-right: auto;

  .header {
    margin-top: 138px;
    color: var(--Text-Text-Main, #011e3d);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 32px;
  }
}
