.modal {
  display: flex;
  position: relative;

  .tabs {
    position: absolute;
    top: 0;
    left: 0;
    width: 192px;
  }

  .content {
    padding: 24px;
    margin-left: 200px;
    border-left: 1px solid #ddd;
    height: 552.7px;
    overflow-y: auto;
  }
}

.footer {
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 224px !important;
  border-top: 1px solid var(--grey-400, #dce5f0);

  .remove {
    flex-grow: 2;

    button,
    svg {
      color: red;
    }
  }

  .cancel {
    margin-right: 5px;
    flex-grow: 0;

    button {
      background: #f1f8fe;
      color: #2499ef;
    }
  }

  .save {
    flex-grow: 0;
  }
}
