.avatar {
  border-radius: var(--avatar-rounded-radius, 12px);
  width: 56px;
  height: 56px;
  background-size: cover;
  background-position: center center;

  .uploadNew {
    padding: 16px;
    opacity: 0.8;
    position: relative;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &:active {
      top: 1px;
    }
  }

  .remove {
    padding: 14px;
    opacity: 0;
    position: relative;
    cursor: pointer;
    transition: opacity 0.3s;

    svg {
      font-size: 30px;
    }

    &:hover {
      opacity: 1;
    }

    &:active {
      top: 1px;
    }
  }
}
