.form {
  .description {
    font-size: 12px;
    color: #738499;
    margin-top: 6px;
  }

  .section {
    border-bottom: 1px solid #dce5f0;
    margin-bottom: 32px;
    padding-bottom: 32px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }

    .sectionHeader {
      color: var(--Text-Text-Main, #011e3d);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      margin-bottom: 24px;
    }
  }

  .dates {
    display: flex;

    .start,
    .finish {
      width: 50%;
    }

    .start {
      margin-right: 25px;
    }

    .finish {
      margin-left: 25px;
    }
  }

  .submitButton {
    display: none;
  }
}
