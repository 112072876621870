.links {
  .description {
    color: var(--text-caption, #738499);

    /* desktop/body2 */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-bottom: 12px;
  }

  .visibility {
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    .label {
      margin-left: -13px;
      color: var(--text-primary, #21253e);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      cursor: pointer;

      &:active {
        position: relative;
        top: 1px;
      }
    }
  }

  .list {
    margin-bottom: 16px;
  }

  .add {
    text-align: center;
  }
}
