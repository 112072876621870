.editor {
  margin: 18px;
  border-radius: var(--radius-L, 16px);
  background: var(--background-default, #fff);
  box-shadow: 0px 0px 16px 0px var(--primary-8, rgba(57, 78, 202, 0.08));
  padding: 20px;

  &[data-edit='edit'] {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;

    .description {
      display: none;
    }

    .header {
      display: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .icon {
      border-radius: 4px;
      background: var(--Primary-100, #e5f3fd);
      padding: 6px 6px 4px 6px;
      margin-right: 16px;
      flex-grow: 0;

      svg {
        font-size: 30px;
        color: #2499ef;
      }
    }

    .title {
      color: var(--Text-Text-Main, #011e3d);
      font-size: 24px;
      font-weight: 600;
      margin-right: 12px;
      flex-grow: 0;
    }

    .link {
      padding: 4px 4px 0px 4px;
      border-radius: 4px;
      cursor: pointer;
      display: block;
      flex-grow: 0;

      &:hover {
        background: #e5f3fd;
      }

      svg {
        color: #2499ef;
      }
    }

    .spacing {
      flex-grow: 2;
    }

    .button {
      display: block;

      &[data-edit='edit'] {
        display: none;
      }
    }
  }

  .description {
    color: var(--text-caption, #738499);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 12px;
  }
}
