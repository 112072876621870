.picker {
  position: absolute;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &[data-init="true"] {
    opacity: 1;
  }

  .hex {
    margin-top: 10px;
    display: flex;
    align-items: center;

    label {
      display: block;
      font-size: 14px;
      color: #555;
      margin-right: 10px;
    }

    input {
      display: block;
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #ccc;
      width: 70px;
    }
  }
}
