.color {
  width: 18px;
  height: 18px;
  margin: 9px;
  border-radius: 50px;
  cursor: pointer;

  &:hover {
    outline: 3px solid #cfd8dc;
  }

  &[data-checked='checked'] {
    width: 26px;
    height: 26px;
    margin: 5px;
    outline: 3px solid #cfd8dc;

    &:last-of-type {
      .checked {
        display: block;
      }
    }
  }

  .checked {
    position: relative;
    top: 3.5px;
    left: 4px;
    text-shadow: 3px 6px rgba(255, 165, 0, 0.75);
    display: none;

    &[data-reversed='reversed'] {
      svg {
        color: #000;
      }
    }

    svg {
      color: #fff;
      font-size: 17px;
    }
  }
}
