.options {
  display: flex;
  margin-top: 12px;

  .option {
    display: block;
    padding: 8px 10px;
    border-radius: 8px;
    color: var(--text-caption, #738499);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    margin-right: 8px;
    cursor: pointer;
    user-select: none;

    &[data-active='active'] {
      background: rgba(36, 153, 239, 0.08);
      color: #2499ef;
    }
  }
}
