.page {
  background: linear-gradient(
      0deg,
      var(--primary-12, rgba(57, 78, 202, 0.12)) 0%,
      var(--primary-12, rgba(57, 78, 202, 0.12)) 100%
    ),
    var(--background-default, #fff);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 10px 15px -3px inset,
    rgba(0, 0, 0, 0.01) 0px 4px 6px -4px;

  &[data-edit='edit'] {
    border-radius: 0;
  }

  .container {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;

    .section {
      border-radius: var(--radius-M, 12px);
      background: var(--background-default, #fff);
      margin-bottom: 12px;
      padding: 16px;

      .content {
        color: var(--text-caption, #738499);

        /* desktop/body2 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */

        p {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .sectionHeader {
        color: var(--text-primary, #21253e);

        /* desktop/h6 */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        margin-bottom: 8px;
      }

      &:first-of-type {
        border-radius: 0 0 12px 12px;
      }
    }
  }

  .avatarAndTitle {
    display: flex;
    margin-bottom: 16px;

    .avatar {
      margin-right: 16px;
      flex-grow: 0;
    }

    .title {
      color: var(--text-primary, #21253e);
      font-family: Roboto;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      flex-grow: 2;
    }
  }
  .subsection {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .subtitle {
    color: var(--Text-Text-400, #335474);
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }

  .subcontent {
    color: var(--Text-Text-Main, #011e3d);

    /* desktop/body2 */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-bottom: 16px;
  }

  .pageColor {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }

  .title,
  .slogan {
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.paymentOptions {
  display: flex;

  & > section {
    width: 50%;

    &:first-of-type {
      margin-right: 12px;
    }

    &:last-of-type {
      margin-left: 12px;
    }
  }
}

.nameAdornment {
  color: var(--text-primary, #738499);

/* desktop/body2 */
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
}

.nameInput {
  margin-left: -7px !important;
  position: relative !important;
  // bottom: 0.5px !important;
}
