.input {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  // padding: 15px 16px;
  background: #ffffff;
  border: 1px solid #dce5f0;
  border-radius: 8px;
  font-size: 13px;
  width: 100%;

  .icon {
    flex-grow: 0;
    padding: 15px 0 15px 16px;

    svg {
      font-size: 15px;
    }
  }

  .element {
    flex-grow: 2;

    input {
      display: block;
      font-size: 13px;
      border: none;
      padding: 15px 16px;
      outline: none;
      font-family: 'Roboto';
      font-size: 13px;
      color: #738499;
      width: 100%;
      border-radius: 8px;

      &::placeholder {
        font-family: 'Roboto';
        font-size: 13px;
        color: #738499;
      }
    }
  }
}
