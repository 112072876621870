.button {
  white-space: nowrap;
  font-family: 'Roboto', sans-serif !important;

  &[data-color='secondary'] {
    background: #f1f8fe;
    color: #2499ef;
    border: none;

    &:hover {
      background: #c6e7ff;
    }
  }

  &[data-color='error'] {
    // background: rgba(255, 49, 111, 0.15) !important;
    border-radius: 4px !important;
    background: var(--error-main, #fe3c3c) !important;
    color: #fff !important;
    border: none;

    &:hover {
      background: var(--error-main, #fe3c3c) !important;
      color: #fff !important;
    }
  }

  &[data-size='small'] {
    padding: 7px 17px !important;
    font-size: 11.5px !important;

    &[data-start-icon='t'] {
      padding: 7px 17px 7px 13px !important;
    }
  }

  &[data-variant='text'] {
    padding: 10px 26px;

    &[data-color='error'] {
      color: #ff316f !important;
      background: transparent !important;

      &:hover {
        background: lighten(#ff316f, 37%) !important;
      }
    }

    &[disabled] {
      color: grey !important;
    }
  }
}
