.palette {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
