.select {
  display: block;
}

.item {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;

  .avatar {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #ccc;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .name {
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 3px;
  }

  .email {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 40;
    line-height: normal;
    margin-bottom: 3px;
  }
}
