.item {
  &[data-active='active'] {
    .header {
      // background: #f5f8fa;

      .title {
        color: #2499ef;
      }

      .leftIcon {
        svg {
          color: #2499ef;
          fill: #2499ef;
        }
      }
    }
  }

  &[data-sub='sub'] {
    .header {
      padding: 13px 10.5px;

      .leftIcon {
        background: #e5f3fd;
        padding: 3px;
        border-radius: 3px;
      }
    }
  }

  &[data-expanded='expanded'] {
    & > .header {
      // background: #f5f8fa;

      .leftIcon {
        transform: rotate(180deg);
      }

      .title {
        color: #2499ef;
      }
    }

    .subItems {
      opacity: 1;
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding: 16px 13.5px;
    background: #fff;
    cursor: pointer;
    transition: 0.3s background;

    &:hover {
      background: #f5f8fa;
    }

    .leftIcon {
      font-size: 14px;
      margin-right: 8px;
      position: relative;
      top: 1px;
      flex-grow: 0;
      transition: 0.5s transform;

      svg {
        color: #b1c9dc;
        fill: #b1c9dc;
      }
    }

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #011e3d;
      flex-grow: 2;
    }

    .rightIcon {
      font-size: 14px;
      margin-right: 8px;
      position: relative;
      top: 1px;
      flex-grow: 0;
    }
  }

  .subItems {
    margin-left: 20px;
    opacity: 0;
    transition: 0.5s opacity;
  }
}
