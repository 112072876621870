.avatar {
  display: flex;
  align-items: center;
  margin-top: 12px;

  .droparea {
    text-align: center;
    width: 160px;
    height: 100px;
    // padding: 55px 20px 20px 20px;
    border-radius: var(--radius-S, 8px);
    background: var(--grey-8, rgba(119, 121, 138, 0.08));
    margin-right: 16px;
    cursor: pointer;
    transition: 0.3s background;
    user-select: none;
    position: relative;
    padding-top: 22px;

    &:active {
      top: 1px;
    }

    &:hover {
      background: var(--grey-8, rgba(119, 121, 138, 0.16));
    }

    .label {
      color: var(--text-disabled, #a1a4af);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }

    .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center center;
      border-radius: var(--radius-S, 8px);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        .delete {
          opacity: 1;
        }
      }

      .delete {
        opacity: 0;
        transition: 0.3s opacity;

        .label {
          color: var(--error-main, #fe3c3c);

          /* desktop/caption */
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }

        .icon {
          svg {
            color: #fe3c3c;
            font-size: 24px;
          }
        }
      }
    }
  }

  .description {
    color: var(--text-caption, #738499);
    width: 226px;

    /* desktop/caption */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */

    .line {
      margin-bottom: 8px;
    }

    .error {
      color: red;
    }
  }
}
