.list {
  .description {
    color: var(--Text-Text-Muted-300, var(--text-caption, #738499));

    /* desktop/caption */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-bottom: 12px;
  }

  .button {
    margin-bottom: 24px;
  }

  .list {
    margin-bottom: 24px;
    max-height: 300px;
    overflow-y: auto;
    padding-right: 5px;

    .placeholder {
      color: var(--Text-Text-Muted-300, var(--text-caption, #738499));
      text-align: center;

      /* desktop/caption */
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      padding: 100px 0;
    }
  }
}
