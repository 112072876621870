.table {
  width: 100%;
  padding: 2px;

  .name {
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
    cursor: pointer;

    &[data-user='user'] {
      color: var(--primary-500, #2499ef);
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .city {
    color: var(--text-text-muted-300, #738499);
    font-size: 12px;
    font-family: Roboto;
  }

  .telegram {
    color: var(--primary-500, #2499ef);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
  }

  .details {
    color: var(--text-text-muted-300, #738499);
    font-size: 13px;
    font-family: Roboto;
    line-height: 140%;

    .community {
      font-size: 14px;
      font-weight: 500;
    }

    .role {
      &[data-manager='manager'] {
        color: var(--primary-500, #2499ef);
      }
    }
  }

  table {
    width: 100%;

    thead {
      th {
        padding: 12px 16px;
        background: #f1f6f9;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        color: #738499;
        vertical-align: middle;

        &:first-of-type {
          border-radius: 8px 0 0px 0px;
        }

        &:last-of-type {
          border-radius: 0 8px 0 0;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        background: #fff;
        transition: 0.3s background;

        &:hover {
          background: #f1f6f9;
        }
      }
      td {
        border-width: 1px 0px;
        border-style: solid;
        border-color: #e9eff6;
        padding: 24px 16px;
        color: var(--text-text-main, #011e3d);
        font-size: 14px;
        font-family: Roboto;
        vertical-align: middle;

        &:last-of-type {
          width: 200px;
        }
      }
    }
  }
}
