.member {
  background: #fff;
  border-radius: var(--radius-L, 16px);
  background: var(--background-default, #fff);
  box-shadow: 0px 0px 24px 0px var(--grey-24, rgba(119, 121, 138, 0.24));
  position: fixed;
  width: 450px;
  top: 200px;
  left: 400px;

  .header {
    border-bottom: 1px solid var(--components-divider, rgba(161, 164, 175, 0.2));
    color: var(--text-primary, #21253e);

    /* desktop/h6 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    padding: 16px 20px;
    display: flex;
    align-items: center;

    .title {
      flex-grow: 2;
    }

    .close {
      flex-grow: 0;
    }
  }

  .telegram {
    display: flex;
    align-items: center;

    .checkbox {
      margin-right: 8px;
    }

    .label {
      color: var(--text-primary, #011e3d);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      position: relative;
      top: 0.5px;
      cursor: pointer;
    }
  }

  .content {
    padding: 16px 20px;

    .section {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .sectionHeader {
        color: var(--Text-Text-400, #335474);
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 16px;
      }
    }
  }

  .footer {
    padding: 16px 20px;
    text-align: right;
  }
}
