.filters {
  margin-top: 12px;
}

.label {
  color: var(--text-secondary, #335474);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}
