.community {
  padding: 8px;
  background: #f7f8fb;
  border-radius: 8px;
  margin-bottom: 4px;
  display: flex;
  position: relative;
  cursor: pointer;
  transition: 0.3s background;

  &:hover {
    background: #c6e7ff;

    .buttons {
      display: flex;
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: #ccc;
    margin-right: 12px;
    background-size: cover;
  }

  .details {
    .title {
      overflow: hidden;
      color: var(--text-primary, #21253e);
      text-overflow: ellipsis;

      /* desktop/subtitle1 */
      font-family: Roboto;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 146.667% */
      margin-bottom: 4px;
    }

    .managers {
      overflow: hidden;
      color: var(--text-caption, #738499);
      text-overflow: ellipsis;
      line-height: 160%;

      /* desktop/caption */
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */

      .manager {
        overflow: hidden;
        color: #2499ef;
        text-overflow: ellipsis;

        /* desktop/caption */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
    }
  }

  .buttons {
    position: absolute;
    display: flex;
    top: 10px;
    right: 10px;
    display: none;

    svg {
      font-size: 17px !important;
    }

    .open,
    .edit {
      svg {
        color: #777;
      }
    }

    .remove {
      svg {
        color: red;
      }
    }
  }
}
