.banner {
  display: flex;
  border-radius: var(--radius-S, 8px);
  background: #fff;
  box-shadow: 0px 4px 10px 2px rgba(19, 80, 124, 0.06);
  margin-bottom: 16px;
  padding: 20px;
  align-items: center;
  font-family: 'Roboto';
  transition: 0.5s background, 0.5s box-shadow;

  &:hover {
    // background: rgba(57, 78, 202, 0.01);
    box-shadow: 0px 1px 5px 2px rgba(19, 80, 124, 0.15);
  }

  .details {
    margin-right: 20px;
    flex-grow: 2;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .title {
        color: #272b30;
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
      }

      .icon {
        background: #e5f3fd;
        margin-right: 12px;
        padding: 4px;
        border-radius: 4px;
        width: 32px;
        height: 32px;
        overflow: hidden;

        svg {
          width: 24px;
          height: 24px;
          color: #45abf6;
          fill: #45abf6;
        }
      }
    }

    .description {
      color: var(--Leaderport-text-grey, #747889);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      margin-bottom: 16px;
    }
  }

  .image {
    flex-grow: 0;
  }
}
