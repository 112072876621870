.content {
  padding: 24px;
  padding-bottom: 60px;
}

.footer {
  padding: 12px 24px;
  border-top: 1px solid var(--Grey-400, #dce5f0);
  display: flex;
  align-items: center;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1000000000;

  .spacing {
    flex-grow: 2;
  }
}
