.modal {
  display: flex;

  .tabs {
    flex-grow: 0;
    border-right: 1px solid var(--divider, #dce5f0);
    width: 200px;
    height: 452px;
  }

  .content {
    flex-grow: 2;
    height: 452px;
    width: 500px;
    padding: 16px 24px;
  }
}
