.logo {
  padding: 16px 13.5px;
  display: block;

  &[data-default='default'] {
    img {
      width: 24px;
      height: 24px;
    }
  }

  img {
    max-width: 80%;
  }

  svg {
    width: 28px;
    height: 28px;
  }
}
